import { SliderPaginationOptions } from "@ecp-boxes/settingsPatterns/boxes/BoxSlider/StyledSlider.types";

import {
  ArticleListBoxType,
  ArticleListSourceOptions,
  GridPaginationOptions,
} from "@ecp-redux/dto/article.type";
import {
  HoverEffects,
  ImagePlacement,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { createTextStyleShowSettingScheme } from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { z } from "zod";

const order = [
  "articleTitle",
  "articleDescription",
  "articleCategory",
  "articleCreationDate",
  "image",
] as const;

export const BoxArticleListContentScheme = z.object({
  heading: z.string().default("heading").catch("heading"),
  article: z
    .object({
      contentSource: z
        .nativeEnum(ArticleListSourceOptions)
        .default(ArticleListSourceOptions.ARTICLE_CATEGORIES)
        .catch(ArticleListSourceOptions.ARTICLE_CATEGORIES),
      articleCategories: z
        .array(
          z.object({ id: z.string().or(z.number()), name: z.string() })
        )
        .optional(),
    })
    .default({ contentSource: ArticleListSourceOptions.ARTICLE_CATEGORIES })
    .catch({ contentSource: ArticleListSourceOptions.ARTICLE_CATEGORIES }),
});

export const BoxArticleListDisplaySettingsScheme = z.object({
  gridPaginationStyle: z
    .nativeEnum(GridPaginationOptions)
    .default(GridPaginationOptions.DEFAULT)
    .catch(GridPaginationOptions.DEFAULT),
  sliderPaginationStyle: z
    .nativeEnum(SliderPaginationOptions)
    .default(SliderPaginationOptions.DOTS)
    .catch(SliderPaginationOptions.DOTS),
  slidesRotate: z.array(z.number()).default([20]).catch([20]),
  spacing: z
    .object({
      horizontal: z.number().default(40).catch(40),
      vertical: z.number().default(20).catch(20),
    })
    .default({ horizontal: 40, vertical: 20 })
    .catch({ horizontal: 40, vertical: 20 }),
});

export const BoxArticleListContentSettingsScheme = z.object({
  article: z
    .object({
      type: z
        .nativeEnum(ArticleListBoxType)
        .default(ArticleListBoxType.SLIDER)
        .catch(ArticleListBoxType.SLIDER),
      articlesCount: z.number().default(10).catch(10),
      articlesPerSlide: z.number().default(5).catch(5),
      articlesPerRow: z.number().default(3).catch(3),
      rowCount: z.number().default(2).catch(2),
      contentAlignment: z
        .nativeEnum(AlignmentHorizontalOption)
        .default(AlignmentHorizontalOption.LEFT)
        .catch(AlignmentHorizontalOption.LEFT),
    })
    .default({
      type: ArticleListBoxType.SLIDER,
      articlesCount: 10,
      articlesPerSlide: 5,
      articlesPerRow: 3,
      rowCount: 2,
      contentAlignment: AlignmentHorizontalOption.LEFT,
    })
    .catch({
      type: ArticleListBoxType.SLIDER,
      articlesCount: 10,
      articlesPerSlide: 5,
      articlesPerRow: 3,
      rowCount: 2,
      contentAlignment: AlignmentHorizontalOption.LEFT,
    }),
  heading: createTextStyleShowSettingScheme("typo$$1", "color$$1")
    .default(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}))
    .catch(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({})),
  articleTitle: createTextStyleShowSettingScheme("typo$$1", "color$$1")
    .default(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}))
    .catch(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({})),
  articleDescription: createTextStyleShowSettingScheme("typo$$1", "color$$1")
    .default(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}))
    .catch(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({})),
  articleCategory: createTextStyleShowSettingScheme("typo$$1", "color$$1")
    .default(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}))
    .catch(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({})),
  articleCreationDate: createTextStyleShowSettingScheme("typo$$1", "color$$1")
    .default(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}))
    .catch(createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({})),
  image: z
    .object({
      show: z.boolean().default(true).catch(true),
      height: z.number().default(400).catch(400),
      width: z.number().default(380).catch(380),
      hover: z
        .nativeEnum(HoverEffects)
        .default(HoverEffects.ZOOMIN)
        .catch(HoverEffects.ZOOMIN),
      alignment: z
        .nativeEnum(ImagePlacement)
        .default(ImagePlacement.TOP)
        .catch(ImagePlacement.TOP),
    })
    .default({
      show: true,
      height: 400,
      width: 380,
      hover: HoverEffects.ZOOMIN,
      alignment: ImagePlacement.TOP,
    })
    .catch({
      show: true,
      height: 400,
      width: 380,
      hover: HoverEffects.ZOOMIN,
      alignment: ImagePlacement.TOP,
    }),
  order: z
    .array(z.enum([...order]))
    .default([...order])
    .catch([...order]),
});
