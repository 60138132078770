import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { PageCategoryType } from "@ecp-pageTypes";
import * as articleApi from "@ecp-redux/api/articles";
import {
  ArticleListBoxType,
  ArticleListSourceOptions,
  GridPaginationOptions,
  TArticle,
  TArticleElements,
} from "@ecp-redux/dto/article.type";
import { FilterOperators } from "@ecp-redux/dto/requestPayload.types";
import { ImagePlacement } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { imgResizer } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { DATE_FORMAT } from "../../global/global";
import { asJSON, getImageUrlOrFallback } from "../../helpers/helpers";
import {
  PageContext,
  useGetArticleByContext,
  usePageContext,
} from "../../helpers/pageContext";
import {
  IBoxArticleListContent,
  IBoxArticleListContentSettings,
  IBoxArticleListDisplaySettings,
} from "../../settingsPatterns/boxes/BoxArticleList/StyledBoxArticleList.types";
import StyledArticleImage from "../../settingsPatterns/elements/article/StyledArticleImage/StyledArticleImage";
import { convertTypoIdToValues } from "../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledArticleImageLinkWrapper } from "./BoxArticleList.styled";
import { IArticleListFilter } from "./BoxArticleList.types";

export const onlyPublishedArticlesFilter: IArticleListFilter = {
  name: ArticleListSourceOptions.STATUS,
  operator: FilterOperators.NOT_EQ,
  value: "DRAFT",
};

export const getContentSource = (
  content: IBoxArticleListContent,
  currentArticle: TArticle | null | undefined,
  contextProductSKU: string | number | null,
  contextProductCategoryCode: string | number | null
): IArticleListFilter[] | null => {
  const filters = [onlyPublishedArticlesFilter];
  switch (content.article.contentSource) {
    case ArticleListSourceOptions.ARTICLE_CATEGORIES:
      filters.push({
        name: ArticleListSourceOptions.ARTICLE_CATEGORIES,
        operator: FilterOperators.IN,
        value: content.article.articleCategories
          ?.map((cat) => cat.id.toString())
          .join(";"),
      });
      return filters;

    case ArticleListSourceOptions.RELATED_ARTICLES:
      filters.push({
        name: ArticleListSourceOptions.ID,
        operator: FilterOperators.IN,
        value: currentArticle?.relatedArticlesIds.join(";"),
      });
      return filters;

    case ArticleListSourceOptions.RELATED_PRODUCTS:
      filters.push({
        name: ArticleListSourceOptions.RELATED_PRODUCTS,
        operator: FilterOperators.IN,
        value: contextProductSKU,
      });
      return filters;

    case ArticleListSourceOptions.RELATED_CATEGORIES:
      filters.push({
        name: ArticleListSourceOptions.RELATED_CATEGORIES,
        operator: FilterOperators.IN,
        value: contextProductCategoryCode,
      });
      return filters;
  }
  return null;
};

export const hasContextForRelatedArticle = (
  content: IBoxArticleListContent,
  context: PageContext
): boolean => {
  return (
    content.article.contentSource ===
      ArticleListSourceOptions.RELATED_ARTICLES &&
    context.type === PageCategoryType.ARTICLE
  );
};

export const hasContextForRelatedProducts = (
  content: IBoxArticleListContent,
  context: PageContext
): boolean => {
  return (
    content.article.contentSource ===
      ArticleListSourceOptions.RELATED_PRODUCTS &&
    context.type === PageCategoryType.PRODUCT
  );
};

export const hasContextForArticleCategories = (
  content: IBoxArticleListContent,
  context: PageContext
): boolean => {
  return (
    context.type !== "CONTEXT_ERROR" &&
    content.article.contentSource ===
      ArticleListSourceOptions.ARTICLE_CATEGORIES
  );
};

export const hasContextForRelatedCategories = (
  content: IBoxArticleListContent,
  context: PageContext
): boolean => {
  return (
    content.article.contentSource ===
      ArticleListSourceOptions.RELATED_CATEGORIES &&
    context.type === PageCategoryType.PRODUCT_CATEGORY
  );
};

export const useFetchArticleList = (
  content: IBoxArticleListContent,
  settings: IBoxArticleListContentSettings & IBoxArticleListDisplaySettings,
  editMode: boolean
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const currentArticle = useGetArticleByContext();
  const [getArticles, articles] = articleApi.useGetArticlesMutation();
  const context = usePageContext();
  const [paginatedArticles, setPaginatedArticles] = useState<TArticle[] | []>(
    []
  );

  const getPaginationQuery = () => {
    const currPage = currentPage;

    if (settings.article.type === ArticleListBoxType.SLIDER) {
      return {
        number: currPage,
        size: settings.article.articlesCount,
      };
    } else if (settings.gridPaginationStyle === GridPaginationOptions.DEFAULT) {
      return {
        number: currPage,
        size: settings.article.rowCount * settings.article.articlesPerRow,
      };
    } else {
      return {
        number: currPage,
        size: 8 * settings.article.articlesPerRow,
      };
    }
  };

  const contextProductSKU =
    context.type === PageCategoryType.PRODUCT ? context.productSku : null;
  const contextProductCategoryCode =
    context.type === PageCategoryType.PRODUCT_CATEGORY
      ? context.productCategoryCode
      : null;

  useEffect(() => {
    if (!articles.isSuccess || !articles?.data?.result) {
      return;
    }
    setPaginatedArticles((prevArticles) => [
      ...prevArticles,
      ...articles.data.result,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);

  useEffect(() => {
    if (
      hasContextForRelatedArticle(content, context) ||
      hasContextForRelatedProducts(content, context) ||
      hasContextForArticleCategories(content, context) ||
      hasContextForRelatedCategories(content, context)
    ) {
      const filters = getContentSource(
        content,
        currentArticle.data,
        contextProductSKU,
        contextProductCategoryCode
      );
      if (filters && filters[1].value) {
        getArticles({
          page: getPaginationQuery(),
          filter: filters,
          sort: [
            {
              name: "creationDate",
              desc: true,
            },
          ],
        });
      } else {
        setCurrentPage(1);
        setPaginatedArticles([]);
      }
    } else {
      setCurrentPage(1);
      setPaginatedArticles([]);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    editMode,
    currentPage,
    content.article.contentSource,
    asJSON(content.article.articleCategories),
    asJSON(settings.slidesRotate),
    settings.article.articlesCount,
    settings.article.articlesPerRow,
    settings.article.articlesPerSlide,
    settings.sliderPaginationStyle,
    settings.article.rowCount,
    settings.article.type,
    currentArticle.data?.id,
    contextProductSKU,
    contextProductCategoryCode,
  ]);

  useEffect(() => {
    setCurrentPage(1);
    setPaginatedArticles([]);
  }, [editMode]);

  const getRefreshedCache = () =>
    paginatedArticles.length === 0 ? [] : articles?.data?.result;

  return {
    articles:
      settings.gridPaginationStyle === GridPaginationOptions.DEFAULT
        ? getRefreshedCache()
        : paginatedArticles,
    pagination: articles?.data?.page,
    currentPage,
    setCurrentPage,
  };
};

export const getSortedElements = (
  settings: IBoxArticleListContentSettings & IBoxArticleListDisplaySettings,
  article: TArticle,
  theme: IThemeState
) => {
  const articleUrl = getImageUrlOrFallback(
    article.imageUrl,
    theme.advanceSettings.settings.backgroundUrl
  );
  const articleAlignment = settings.article.contentAlignment;
  const elements = {
    image: settings.image.show && (
      <StyledArticleImageLinkWrapper
        className={`article-list__image-container`}
        alignment={articleAlignment}
      >
        <LinkWrapper
          className={`article-list__link imageContainer`}
          href={article.articleUrl}
        >
          <StyledArticleImage
            src={imgResizer(articleUrl, settings.image.width)}
            alt={article.title}
            $settings={settings.image}
          />
        </LinkWrapper>
      </StyledArticleImageLinkWrapper>
    ),
    articleTitle: settings.articleTitle.show && (
      <StyledArticleImageLinkWrapper alignment={articleAlignment}>
        <LinkWrapper
          className={`article-list__link imageContainer`}
          href={article.articleUrl}
        >
          <StyledText $settings={settings.articleTitle}>
            {article.title}
          </StyledText>
        </LinkWrapper>
      </StyledArticleImageLinkWrapper>
    ),
    articleDescription: settings.articleDescription.show && (
      <StyledArticleImageLinkWrapper alignment={articleAlignment}>
        <LinkWrapper
          className={`article-list__link imageContainer`}
          href={article.articleUrl}
        >
          <StyledText $settings={settings.articleDescription}>
            {article.description}
          </StyledText>
        </LinkWrapper>
      </StyledArticleImageLinkWrapper>
    ),
    articleCategory: settings.articleCategory.show && (
      <StyledArticleImageLinkWrapper alignment={articleAlignment}>
        <LinkWrapper
          className={`article-list__link imageContainer`}
          href={article.articleUrl}
        >
          <StyledText $settings={settings.articleCategory}>
            {article?.category?.name}
          </StyledText>
        </LinkWrapper>
      </StyledArticleImageLinkWrapper>
    ),
    articleCreationDate: settings.articleCreationDate.show && (
      <StyledArticleImageLinkWrapper alignment={articleAlignment}>
        <LinkWrapper
          className={`article-list__link imageContainer`}
          href={article.articleUrl}
        >
          <StyledText $settings={settings.articleCreationDate}>
            {format(new Date(article.creationDate), DATE_FORMAT)}
          </StyledText>
        </LinkWrapper>
      </StyledArticleImageLinkWrapper>
    ),
  };

  return sortElements(elements, settings, theme);
};

const getHeightByGlobalSettings = (
  el: TArticleElements,
  settings: IBoxArticleListContentSettings & IBoxArticleListDisplaySettings,
  theme: IThemeState
) => {
  if (el === "image") return;
  const fontValue = convertTypoIdToValues(settings[el].font, theme.typography);

  switch (el) {
    case "articleCreationDate":
      return fontValue.lineHeight;
    case "articleCategory":
      return fontValue.lineHeight;
    default:
      return 2 * fontValue.lineHeight;
  }
};

export const sortElements = (
  elements: Record<TArticleElements, React.ReactElement | false>,
  settings: IBoxArticleListContentSettings & IBoxArticleListDisplaySettings,
  theme: IThemeState
) => {
  const styles = {
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
    width: "auto",
    WebkitLineClamp: 2,
    order: 2,
  };

  return (
    <>
      {settings.order?.map((el, index) => {
        const element = (elements as any)[el];
        if (element === false) return;

        const cloneElement = React.cloneElement(element, {
          key: index,
          style: {
            height: getHeightByGlobalSettings(el, settings, theme) || "auto",
            WebkitBoxOrient: el !== "image" ? "vertical" : "initial",
            ...styles,
          },
        });
        if (settings.image.alignment === ImagePlacement.TOP)
          return cloneElement;
        return el === "image" && cloneElement;
      })}

      {settings.image.alignment !== ImagePlacement.TOP && (
        <div>
          {settings.order?.map((el, index) => {
            const element = (elements as any)[el];
            if (element === false) return;
            const cloneElement = React.cloneElement(element, {
              key: index,
              style: {
                height:
                  getHeightByGlobalSettings(el, settings, theme) || "auto",
                WebkitBoxOrient: el !== "image" ? "vertical" : "initial",
                ...styles,
              },
            });

            return el !== "image" && cloneElement;
          })}
        </div>
      )}
    </>
  );
};
